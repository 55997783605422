import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import Typography from "@mui/material/Typography";

const ToothSelection = ({ selectedTeeth, handleToothSelect }) => {
  const allTeeth = [
    'UR8', 'UR7', 'UR6', 'UR5', 'UR4', 'UR3', 'UR2', 'UR1',
    'UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8', 
    
    'LR8', 'LR7', 'LR6', 'LR5', 'LR4', 'LR3', 'LR2', 'LR1',
    'LL1', 'LL2', 'LL3', 'LL4', 'LL5', 'LL6', 'LL7', 'LL8', 
    
  ];

  const isSelected = (tooth) => selectedTeeth[`tooth_${tooth.toLowerCase()}`] === 'Yes';

  return (
    <Box
      sx={{
        border: '1px solid #ddd',
        p: 2,
        bgcolor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '72%',
      }}
    >
      <Typography variant="h6" component="h4">
        Select Teeth
      </Typography>
      
      <Grid container spacing={0.5} justifyContent="center">
        {allTeeth.slice(0, 16).map((tooth) => (
          <Grid item key={tooth} xs={0.75}>
            <Button
              variant="outlined"
              onClick={() => handleToothSelect(`tooth_${tooth.toLowerCase()}`)}
              sx={{
                minWidth: '30px',
                height: '50px',
                width: '45px',
                backgroundColor: isSelected(tooth) ? 'lightblue' : 'transparent',
                borderColor: isSelected(tooth) ? 'blue' : 'grey',
                fontSize: '0.75rem',
                color: isSelected(tooth) ? 'blue' : 'inherit',
                borderRadius: '4px',
                textTransform: 'none',
                '&:hover': {
                  borderColor: isSelected(tooth) ? 'blue' : '#aaa',
                }
              }}
            >
              {tooth}
            </Button>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={0.5} justifyContent="center" sx={{ mt: 1 }}>
        {allTeeth.slice(16).map((tooth) => (
          <Grid item key={tooth} xs={0.75}>
            <Button
              variant="outlined"
              onClick={() => handleToothSelect(`tooth_${tooth.toLowerCase()}`)}
              sx={{
                minWidth: '30px',
                height: '50px',
                width: '45px',
                backgroundColor: isSelected(tooth) ? 'lightblue' : 'transparent',
                borderColor: isSelected(tooth) ? 'blue' : 'grey',
                fontSize: '0.75rem',
                color: isSelected(tooth) ? 'blue' : 'inherit',
                borderRadius: '4px',
                textTransform: 'none',
                '&:hover': {
                  borderColor: isSelected(tooth) ? 'blue' : '#aaa',
                }
              }}
            >
              {tooth}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ToothSelection;
