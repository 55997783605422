import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import ToothSelectionGrid from "./ToothSelectionGrid";

const FormModal = ({
  open,
  handleClose,
  formData,
  handleInputChange,
  handleSave,
  handleCheckboxChange,
  handleToothSelect,
  selectedTeeth,
}) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    maxWidth: "2430px",
    bgcolor: "#f3e5f5",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    overflowY: "auto",
    "@media (max-width: 600px)": {
      width: "95%",
      maxWidth: "100%",
      p: 2,
    },
  };

  const isSelected = (tooth) => selectedTeeth.includes(tooth);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Personal Dental Treatment Plan
        </Typography>
        <Box component="form" sx={{ mt: 2 }} noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                id="textBox"
                label="Provider’s details"
                name="provider_details"
                value={formData.provider_details}
                onChange={handleInputChange}
                multiline
                minRows={8}
                variant="outlined"
              />
              <TextField
                fullWidth
                margin="normal"
                id="field1"
                label="Performer number"
                name="performer_number"
                value={formData.performer_number}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                id="field1"
                label="Surname"
                name="patient_surname"
                value={formData.patient_surname}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                margin="normal"
                id="field2"
                label="Forename"
                name="patient_forename"
                value={formData.patient_forename}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                margin="normal"
                id="field3"
                label="Date of Birth"
                name="patient_dob"
                value={formData.patient_dob}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                margin="normal"
                id="field4"
                label="Date of Acceptance"
                name="patient_date_of_acceptance"
                value={formData.patient_date_of_acceptance}
                onChange={handleInputChange}
              />
              <Typography variant="body2" sx={{ mt: 2 }}>
                The dentist named on this form is providing you with a course of
                treatment. Information regarding your NHS dental treatment is
                detailed overleaf.
              </Typography>
            </Grid>
          </Grid>

          <Box mt={5}>
            <hr />
          </Box>

          {/* New Box with Additional Input Fields */}
          <Box
            sx={{ mt: 4, border: "1px solid #ccc", p: 2, bgcolor: "#f9f9f9" }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4}>
                <Typography variant="h4" component="h3">
                  Oral Health Assessment
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="additionalField1"
                      label="Date of examination"
                      name="patient_date_of_examination"
                      value={formData.patient_date_of_examination}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box mt={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              formData.treatment_on_referral_only === "Yes"
                            }
                            onChange={handleInputChange}
                            name="treatment_on_referral_only"
                            color="primary"
                          />
                        }
                        label="Treatment on referral only"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Box sx={{ p: 2, bgcolor: "#f9f9f9", width: "100%" }}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="textBox"
                  label="Care and Treatment required"
                  name="care_and_treatment_required"
                  value={formData.care_and_treatment_required}
                  onChange={handleInputChange}
                  multiline
                  minRows={8}
                  variant="outlined"
                />
              </Box>
            </Grid>
          </Box>

          {/* New Box with Small Input Field and Checkbox */}
          <Box
            sx={{
              mt: 4,
              border: "1px solid #ddd",
              p: 2,
              bgcolor: "#f9f9f9",
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="smallField"
                  label="No Treatment required at this time"
                  name="no_treatment_required"
                  value={formData.no_treatment_required}
                  onChange={handleInputChange}
                  type="number"
                  inputProps={{ min: 1 }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <TextField
                  fullWidth
                  margin="normal"
                  id="smallField"
                  label="I recommend a checkup in about months"
                  name="checkup_in_months"
                  value={formData.checkup_in_months}
                  onChange={handleInputChange}
                  type="number"
                  inputProps={{ min: 1 }}
                />
              </Grid>
            </Grid>
            <Typography variant="body2" sx={{ mt: 2 }}>
              The NHS provides all the treatment necessary to secure and
              maintain your oral health. There are some treatments (mainly
              cosmetic) that are not normally available under the NHS, and you
              may choose to have these provided privately. You may also choose
              to have some treatment provided privately as an alternative to NHS
              treatment. The dentist will discuss these options with you so that
              you can make an informed choice.
            </Typography>
          </Box>

          {/* New Box with Grouped Checkbox Lists in Three Sections */}
          <Box
            sx={{
              mt: 1,
              border: "1px solid #ddd",
              p: 2,
              bgcolor: "#f9f9f9",
            }}
          >
            {/* Left Section with Grouped Checkbox Lists */}
            <Box
              sx={{
                mt: 1,
                border: "1px solid #ddd",
                p: 2,
                bgcolor: "#f9f9f9",
              }}
            >
              <Typography variant="h6" component="h4" gutterBottom>
                Proposed NHS Treatment
              </Typography>
              <Grid container spacing={2}>
                {/* Diagnosis and Maintenance Column */}
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" component="h5" gutterBottom>
                    Diagnosis and Maintenance
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formData.nhs_treatment_examination_advice === "Yes"
                        }
                        onChange={handleInputChange}
                        name="nhs_treatment_examination_advice"
                        color="primary"
                      />
                    }
                    label="Examination and advice"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.nhs_treatment_radiographs === "Yes"}
                        onChange={handleInputChange}
                        name="nhs_treatment_radiographs"
                        color="primary"
                      />
                    }
                    label="Radiographs, study casts & photos"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.nhs_treatment_prevention === "Yes"}
                        onChange={handleInputChange}
                        name="nhs_treatment_prevention"
                        color="primary"
                      />
                    }
                    label="Prevention"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formData.nhs_treatment_scaling_polishing === "Yes"
                        }
                        onChange={handleInputChange}
                        name="nhs_treatment_scaling_polishing"
                        color="primary"
                      />
                    }
                    label="Scaling, polishing, marginal correction of fillings"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formData.nhs_treatment_adjustment_easing_denture ===
                          "Yes"
                        }
                        onChange={handleInputChange}
                        name="nhs_treatment_adjustment_easing_denture"
                        color="primary"
                      />
                    }
                    label="Adjustments & easing denture(s)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formData.nhs_treatment_diagnosis_other === "Yes"
                        }
                        onChange={handleInputChange}
                        name="nhs_treatment_diagnosis_other"
                        color="primary"
                      />
                    }
                    label="Other"
                  />
                </Grid>

                {/* Treatment Column */}
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" component="h5" gutterBottom>
                    Treatment
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formData.nhs_treatment_non_surgical_periodontal ===
                          "Yes"
                        }
                        onChange={handleInputChange}
                        name="nhs_treatment_non_surgical_periodontal"
                        color="primary"
                      />
                    }
                    label="Non-Surgical periodontal treatment"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formData.nhs_treatment_surgical_periodontal === "Yes"
                        }
                        onChange={handleInputChange}
                        name="nhs_treatment_surgical_periodontal"
                        color="primary"
                      />
                    }
                    label="Surgical periodontal treatment"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formData.nhs_treatment_permenant_filling === "Yes"
                        }
                        onChange={handleInputChange}
                        name="nhs_treatment_permenant_filling"
                        color="primary"
                      />
                    }
                    label="Permanent fillings & sealant restorations"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.nhs_treatment_endodontics === "Yes"}
                        onChange={handleInputChange}
                        name="nhs_treatment_endodontics"
                        color="primary"
                      />
                    }
                    label="Endodontics"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formData.nhs_treatment_extraction_other_oral === "Yes"
                        }
                        onChange={handleInputChange}
                        name="nhs_treatment_extraction_other_oral"
                        color="primary"
                      />
                    }
                    label="Extractions & other oral surgery"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formData.nhs_treatment_occlusal_splints === "Yes"
                        }
                        onChange={handleInputChange}
                        name="nhs_treatment_occlusal_splints"
                        color="primary"
                      />
                    }
                    label="Occlusal splints (fabricated in the mouth)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formData.nhs_treatment_relining_rebasing === "Yes"
                        }
                        onChange={handleInputChange}
                        name="nhs_treatment_relining_rebasing"
                        color="primary"
                      />
                    }
                    label="Relining, rebasing and additions to dentures"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.nhs_treatment_other === "Yes"}
                        onChange={handleInputChange}
                        name="nhs_treatment_other"
                        color="primary"
                      />
                    }
                    label="Other"
                  />
                </Grid>

                {/* Appliances Column */}
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" component="h5" gutterBottom>
                    Appliances
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formData.nhs_treatment_veneers_pinlays === "Yes"
                        }
                        onChange={handleInputChange}
                        name="nhs_treatment_veneers_pinlays"
                        color="primary"
                      />
                    }
                    label="Veneers, pinlays, inlays, onlays, crowns"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.nhs_treatment_bridges === "Yes"}
                        onChange={handleInputChange}
                        name="nhs_treatment_bridges"
                        color="primary"
                      />
                    }
                    label="Bridges"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.nhs_treatment_dentures === "Yes"}
                        onChange={handleInputChange}
                        name="nhs_treatment_dentures"
                        color="primary"
                      />
                    }
                    label="Dentures"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formData.nhs_treatment_appliances_other === "Yes"
                        }
                        onChange={handleInputChange}
                        name="nhs_treatment_appliances_other"
                        color="primary"
                      />
                    }
                    label="Other appliances"
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    id="textBox"
                    label="Other"
                    name="nhs_treatment_other_custom"
                    value={formData.nhs_treatment_other_custom}
                    onChange={handleInputChange}
                    multiline
                    minRows={7}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Right Section with Grouped Checkbox Lists */}

            <Box
              sx={{
                mt: 1,
                border: "1px solid #ddd",
                p: 2,
                bgcolor: "#f9f9f9",
              }}
            >
              <Typography variant="h6" component="h4">
                Proposed Private Treatment
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6" component="h5" mt={1}>
                  Diagnosis and Maintenance
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="diagnosis1"
                      label="Radiographs, study casts & photos £"
                      name="prv_treatment_radiographs"
                      value={formData.prv_treatment_radiographs}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="diagnosis2"
                      label="Prevention £"
                      name="prv_treatment_prevention"
                      value={formData.prv_treatment_prevention}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="diagnosis3"
                      label="Scaling, polishing, marginal correction of fillings £"
                      name="prv_treatment_scaling"
                      value={formData.prv_treatment_scaling}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="diagnosis4"
                      label="Other £"
                      name="Other £"
                      value={formData.diagnosis4}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                </Grid>

                <Typography variant="h6" component="h5" sx={{ mt: 2 }}>
                  Treatment
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="treatment1"
                      label="Non-Surgical periodontal treatment £"
                      name="prv_treatment_non_surgical_periodontal"
                      value={formData.prv_treatment_non_surgical_periodontal}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="treatment2"
                      label="Surgical periodontal treatment £"
                      name="prv_treatment_surgical_periodontal"
                      value={formData.prv_treatment_surgical_periodontal}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="treatment3"
                      label="Permanent fillings & sealant restorations £"
                      name="prv_treatment_permenant_filling"
                      value={formData.prv_treatment_permenant_filling}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="treatment4"
                      label="Endodontics £"
                      name="prv_treatment_endodontics"
                      value={formData.prv_treatment_endodontics}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="treatment5"
                      label="Extractions & other oral surgery £"
                      name="prv_treatment_extractions"
                      value={formData.prv_treatment_extractions}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="treatment6"
                      label="Other £"
                      name="prv_treatment_other"
                      value={formData.prv_treatment_other}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                </Grid>

                <Typography variant="h6" component="h5" sx={{ mt: 2 }}>
                  Appliances
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="appliance1"
                      label="Veneers, pinlays, inlays, onlays, crowns £"
                      name="prv_treatment_veneers_pinlays"
                      value={formData.prv_treatment_veneers_pinlays}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="appliance2"
                      label="Bridges £"
                      name="prv_treatment_bridges"
                      value={formData.prv_treatment_bridges}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="appliance3"
                      label="Dentures £"
                      name="prv_treatment_dentures"
                      value={formData.prv_treatment_dentures}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="appliance4"
                      label="Other appliances £"
                      name="prv_treatment_appliances_others"
                      value={formData.prv_treatment_appliances_others}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  margin="normal"
                  id="textBox"
                  label="other"
                  name="prv_treatment_other_custom"
                  value={formData.prv_treatment_other_custom}
                  onChange={handleInputChange}
                  multiline
                  minRows={8}
                  variant="outlined"
                />
              </Box>
            </Box>

            
              <Box
                sx={{
                  border: "5px solid #ddd",
                  p: 2,
                  bgcolor: "#ffffff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "72%",
                  marginTop:"10px"
                }}
              >
                <ToothSelectionGrid
                  selectedTeeth={selectedTeeth}
                  handleToothSelect={handleToothSelect}
                />
              </Box>

              {/* Additional Observations */}
              <TextField
                fullWidth
                margin="normal"
                id="textBox"
                label="Additional observations"
                name="additional_observations"
                value={formData.additional_observations}
                onChange={handleInputChange}
                multiline
                minRows={7}
                variant="outlined"
              />
            
          </Box>

          <Box
            sx={{ padding: 2, bgcolor: "#fff", border: "1px solid #ddd" }}
            mt={2}
          >
            <Box mb={2}>
              <Typography variant="h6" component="h5" sx={{ mt: 2 }}>
                Charge band for NHS treatment
              </Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.charges_band_1_nhs === "Yes"}
                    onChange={handleInputChange}
                    name="charges_band_1_nhs"
                    color="primary"
                  />
                }
                label="Charges band 1 NHS"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.charges_band_2_nhs === "Yes"}
                    onChange={handleInputChange}
                    name="charges_band_2_nhs"
                    color="primary"
                  />
                }
                label="Charges band 2 NHS"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.charges_band_3_nhs === "Yes"}
                    onChange={handleInputChange}
                    name="charges_band_3_nhs"
                    color="primary"
                  />
                }
                label="Charges band 3 NHS"
              />
            </Box>
            <hr />
            <Grid container spacing={3}>
              {/* Left Section */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" component="h5" sx={{ mb: 1 }}>
                  Charge for NHS treatment £
                </Typography>
                <TextField
                  fullWidth
                  margin="normal"
                  id="leftField1"
                  label="£"
                  name="charge_for_nhs_treatment"
                  value={formData.charge_for_nhs_treatment}
                  onChange={handleInputChange}
                  variant="outlined"
                  size="small"
                  type="number"
                  inputProps={{ min: 1 }}
                />
                <Typography variant="h6" component="h5" sx={{ mt: 3, mb: 1 }}>
                  Charge for Private treatment £
                </Typography>

                <TextField
                  fullWidth
                  margin="normal"
                  id="leftField1"
                  label="£"
                  name="charge_for_private_treatment"
                  value={formData.charge_for_private_treatment}
                  onChange={handleInputChange}
                  variant="outlined"
                  size="small"
                  type="number"
                  inputProps={{ min: 1 }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{ mr: 1 }}
            >
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default FormModal;
