import * as React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Tutorials.module.scss";
import axios from "axios";
import { SkipNext } from "@mui/icons-material"; // MUI Skip Icon
import { Button, Card, CardContent, Typography } from "@mui/material";

function Tutorials() {
  const navigate = useNavigate();

  React.useEffect(() => {
    axios.put("/user/negate_initial_login");
  }, []);

  const handleSkip = () => {
    navigate("/dashboard");
  };

  return (
    <div
      className={styles.tutorials}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "#f4f6f9", // Light background
        padding: "20px",
      }}
    >
      <Typography
        variant="h3"
        style={{
          fontWeight: "bold",
          marginBottom: "30px",
          color: "#333",
          textAlign: "center",
        }}
      >
        Tutorial
      </Typography>
      <Card
        style={{
          maxWidth: "1200px", // Adjusted max width for better responsiveness
          width: "100%",
          borderRadius: "20px",
          boxShadow: "0px 6px 25px rgba(0, 0, 0, 0.15)", // Slightly larger shadow for emphasis
          overflow: "hidden",
        }}
      >
        <CardContent style={{ padding: "0" }}>
          <video
            controls
            style={{
              display: "block",
              width: "100%",
              height: "auto", // Maintain aspect ratio
              objectFit: "contain", // Ensures no edges are cut
            }}
          >
            <source
              src="https://medicube-new.s3.eu-west-2.amazonaws.com/Dentist+explainer+video.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div
            style={{
              padding: "30px",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF5733", // Vibrant color
                color: "#fff",
                borderRadius: "25px",
                padding: "15px 30px",
                textTransform: "none",
                fontSize: "18px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                minWidth: "250px",
              }}
              startIcon={<SkipNext />}
              onClick={handleSkip}
            >
              Skip & go to dashboard
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default Tutorials;
