import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import styles from "./ImageGallery.module.scss";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

const ImageGallery = ({ showGallery, onClose }) => {
  const [activeTab, setActiveTab] = useState("stock_images");
  const [galleryData, setGalleryData] = useState({
    stock_gifs: [],
    stock_images: [],
    user_gifs: [],
    user_images: [],
  });
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchGalleryData = async () => {
    try {
      const token = axios.defaults.headers.common.Authorization;
      if (!token) {
        console.error("Authorization token is missing.");
        return;
      }
      const response = await axios.get("consent_clause/get_user_media", {
        headers: {
          Authorization: `${token}`,
        },
      });
      setGalleryData(response.data);
    } catch (error) {
      console.error("Error fetching gallery data:", error);
    }
  };

  const renderImages = () => {
    return galleryData[activeTab].map((item) => (
      <div key={item.id} className={styles.imageItem}>
        <img src={item.url} alt={item.name} className={styles.galleryImage} />
        <p>{item.name}</p>
      </div>
    ));
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setUploadedImages((prevImages) => [...prevImages, ...acceptedFiles]);
    },
    multiple: true,
  });

  const removeImage = (index) => {
    const newImages = uploadedImages.filter((_, i) => i !== index);
    setUploadedImages(newImages);
  };

  const handleImageUpload = async () => {
    const token = axios.defaults.headers.common.Authorization;
    setLoading(true);
    if (uploadedImages.length > 0) {
      try {
        for (const file of uploadedImages) {
          const formData = new FormData();
          formData.append("file", file);
          await axios.post("consent_clause/upload_user_media_to_s3", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          });
        }
        toast.success("Content added to the Image gallery");
        fetchGalleryData();
        closeImageModal();
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("Error uploading images");
      } finally {
        setLoading(false);
      }
    }
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setUploadedImages([]);
  };

  useEffect(() => {
    if (showGallery) fetchGalleryData();
  }, [showGallery]);

  if (!showGallery) return null;

  return (
    <div className={styles.imageGalleryContainer}>
      <h3>Image Gallery</h3>
      <button
        type="button"
        onClick={fetchGalleryData}
        className={styles.refreshButton}
      >
        <RefreshIcon />
      </button>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Button
          variant="contained"
          onClick={() => setIsImageModalOpen(true)}
          style={{
            fontSize: "15px",
            padding: "12px 20px",
            borderRadius: "30px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#003366",
            color: "white",
          }}
        >
          Upload Images
          <PhotoCameraBackIcon style={{ marginLeft: "10px", fontSize: "20px" }} />
        </Button>
        <Tooltip title={<ImageGalleryTooltip />} placement="top">
          <IconButton
            style={{
              marginLeft: "10px",
              padding: "8px",
              backgroundColor: "#f0f0f0",
              borderRadius: "50%",
            }}
          >
            <InfoIcon style={{ fontSize: "24px", color: "#003366" }} />
          </IconButton>
        </Tooltip>
      </div>
      <div className={styles.tabContainer}>
        {["stock_images", "stock_gifs", "user_images", "user_gifs"].map((tab) => (
          <button
            key={tab}
            type="button"
            className={`${styles.tabButton} ${activeTab === tab ? styles.activeTab : ""}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab.replace("_", " ").toUpperCase()}
          </button>
        ))}
      </div>
      <div className={styles.imageGallery}>{renderImages()}</div>
      <ImageUploadModal
        open={isImageModalOpen}
        onClose={closeImageModal}
        onUpload={handleImageUpload}
        uploadedImages={uploadedImages}
        onRemoveImage={removeImage}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
      />
      <LoadingModal open={loading} />
    </div>
  );
};

const ImageGalleryTooltip = () => (
  <div>
    <Typography variant="h6" style={{ marginBottom: "10px" }}>
      Image Gallery :
    </Typography>
    <List style={{ fontSize: "16px" }}>
      <ListItem>
        <ListItemText primary="Upload directly from the upload image button." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Add image to editor and save; it will automatically be added to the gallery." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Use your mobile to capture and upload images (Coming soon!)." />
      </ListItem>
    </List>
  </div>
);

const ImageUploadModal = ({
  open,
  onClose,
  onUpload,
  uploadedImages,
  onRemoveImage,
  getInputProps,
  getRootProps,
}) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "30px",
        borderRadius: "8px",
        boxShadow: 24,
        minWidth: "1000px",
        minHeight: "900px",
        maxWidth: "1000px",
        maxHeight: "900px",
        overflow: "auto",
      }}
    >
      <h2>Upload Images To Gallery</h2>
      <Button
        variant="outlined"
        component="label"
        fullWidth
        style={{
          marginBottom: "10px",
          padding: "10px 20px",
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        Choose Images/Gif
        <input type="file" hidden {...getInputProps()} />
      </Button>
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #003366",
          padding: "160px",
          textAlign: "center",
          borderRadius: "8px",
          backgroundColor: "#f8f8f8",
          marginBottom: "20px",
        }}
      >
        <p>Drag and drop images/Gif here</p>
        <p>or</p>
        <p>Click to upload images/Gif</p>
      </div>
      <div
        style={{
          marginTop: "20px",
          textAlign: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {uploadedImages.map((file, index) => (
          <div
            key={index}
            style={{ position: "relative", margin: "10px" }}
          >
            <img
              src={URL.createObjectURL(file)}
              alt="Preview"
              style={{
                maxWidth: "200px",
                maxHeight: "200px",
                objectFit: "contain",
                marginBottom: "10px",
              }}
            />
            <Button
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                padding: "5px",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                color: "white",
                borderRadius: "10%",
              }}
              onClick={() => onRemoveImage(index)}
            >
              <DeleteIcon />
            </Button>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            padding: "12px 20px",
            borderRadius: "8px",
            flex: 1,
            marginRight: "10px",
          }}
          onClick={onUpload}
          disabled={uploadedImages.length === 0}
        >
          Upload Images
        </Button>
        <Button
          variant="outlined"
          style={{
            padding: "12px 20px",
            borderRadius: "8px",
            flex: 1,
            marginLeft: "10px",
          }}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Box>
  </Modal>
);

const LoadingModal = ({ open }) => (
  <Modal open={open}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "30px",
        borderRadius: "10px",
        textAlign: "center",
      }}
    >
      <CircularProgress size={50} />
      <Typography variant="h6" sx={{ marginTop: 2 }}>
        Uploading data to cloud storage...
      </Typography>
    </Box>
  </Modal>
);

export default ImageGallery;
