import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import imageCompression from "browser-image-compression";
import styles from "./Templates.module.scss";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

export default function TemplatesComponent({ closeModal }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [practiceName, setPracticeName] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedSite, setSelectedSite] = useState(null);
  const [applyForAllPractices, setApplyForAllPractices] = useState(false);
  const [selectedSitecheck, setSelectedSitecheck] = useState(true);
  const [practiceData, setPracticeData] = useState({});
  const [practiceSites, setPracticeSites] = useState([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isCropping, setIsCropping] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);

  const cropperRef = useRef(null);

  const fetchPracticeSites = async () => {
    try {
      const response = await axios.get(`/site/`);
      setPracticeSites(response.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching practice sites:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPracticeSites();
  }, []);

  const handleChange = (e) => {
    const Id = e.target.value;
    setSelectedSite(Id);
    const selectedSiteObject = practiceSites.find((site) => site.id === Id);
    if (selectedSiteObject) {
      setSelectedSitecheck(false);
      getNameAndLogo(Id);
    } else {
      setSelectedSitecheck(true);
      setPracticeName("");
    }
  };

  const handleDrop = async (acceptedFiles) => {
    const imageFile = acceptedFiles[0];
    const imageUrl = URL.createObjectURL(imageFile);
    setUploadedImageUrl(imageUrl);
    setIsCropping(true); // Show the cropping tool once the image is dropped
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      const cropperInstance = cropperRef.current?.cropper; // Get the cropper instance
      if (cropperInstance) {
        const croppedCanvas = cropperInstance.getCroppedCanvas({
          width: 250, // Set the output image size (width)
          height: 250, // Set the output image size (height)
        });

        const croppedData = croppedCanvas.toDataURL();
        setCroppedImage(croppedData);
        setIsCropping(false); // Hide the cropping tool after cropping
      }
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const mutation = useMutation(
    async ({ id, formData }) => {
      const response = await axios.post(
        `/site/${id}/save_site_consent_clause_data`,
        formData
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("Practice name and logo uploaded successfully.");
        setUploading(false);
        closeModal();
      },
    }
  );

  const getNameAndLogo = async (Id) => {
    try {
      const response = await axios.get(
        `/site/${Id}/get_site_consent_clause_data`
      );
      setPracticeData(response.data);
      setPracticeName(response.data.display_name || "");
    } catch (error) {
      console.error("Error fetching practice data:", error);
    }
  };

  const handleSubmit = async () => {
    if (applyForAllPractices) {
      setIsConfirmationOpen(true);
    } else {
      await uploadData();
    }
  };

  const uploadData = async () => {
    if (!practiceName) {
      toast.error("Please upload an image and enter the practice name.");
      return;
    }

    setUploading(true);
    setUploadError("");
    let base64Image = null;

    try {
      if (croppedImage) {
        base64Image = croppedImage; // Use cropped image
      }

      if (applyForAllPractices) {
        for (const site of practiceSites) {
          const response = await axios.get(
            `/site/${site.id}/get_site_consent_clause_data`
          );
          const formData = {
            logo: base64Image,
            display_name: response.data.display_name,
          };

          await mutation.mutateAsync({ id: site.id, formData });
        }
      }

      const formData = {
        logo: base64Image,
        display_name: practiceName,
      };

      await mutation.mutateAsync({ id: selectedSite, formData });
    } catch (error) {
      console.error("Error uploading data:", error);
      setUploadError("Error uploading data. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: handleDrop,
    maxSize: 1000000, // 1MB file size limit
  });

  return (
    <div>
      <div className={styles.modal}>
        <Box mb={4}>
          <h2 className={styles.header}>Upload Practice Name and Logo</h2>
        </Box>

        <h3 className={styles.header}>Select a Practice</h3>
        <Select
          value={selectedSite}
          onChange={handleChange}
          fullWidth
          variant="filled"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {practiceSites.map((site) => (
            <MenuItem key={site.id} value={site.id}>
              {site.name}
            </MenuItem>
          ))}
        </Select>

        {!selectedSitecheck && (
          <>
            <Box mt={2} className={styles.content}>
              <h3 className={styles.header}>
                Existing Practice Display Name & Logo
              </h3>
              <div className={styles.logoAndName}>
                {practiceData.logo ? (
                  <img
                    src={practiceData.logo}
                    alt="Logo"
                    className={styles.logo}
                  />
                ) : (
                  <div className={styles.noLogoText}>No Logo</div>
                )}
                <h2>{practiceData.display_name}</h2>
              </div>
            </Box>

            <h3 className={styles.header}>
              Display Name for Selected Practice
            </h3>
            <TextField
              label="Practice Name"
              name="practiceName"
              required
              type="text"
              variant="filled"
              fullWidth
              className="mg-b-1"
              value={practiceName}
              onChange={(e) => setPracticeName(e.target.value)}
            />

            <h3 className={styles.header}>Logo Upload</h3>
            <div {...getRootProps()} className={styles.dropzone}>
              <input {...getInputProps()} />
              {uploadedImageUrl && !isCropping ? (
                <img
                  src={uploadedImageUrl}
                  alt="Preview"
                  className={styles.previewImage}
                />
              ) : (
                <>
                  <img
                    src="../../Upload.png"
                    alt="Drop Here"
                    className={styles.dropIcon}
                  />
                  <p className={styles.dropzoneText}>
                    Drag and drop an image here, or click to select a file
                  </p>
                </>
              )}
            </div>

            {isCropping && (
              <>
                <Box mt={2}>
                  <Cropper
                    ref={cropperRef}
                    src={uploadedImageUrl}
                    style={{ height: 250, width: "100%" }}
                    aspectRatio={1} // Fixed aspect ratio for cropping
                    guides={false}
                    minWidth={250} // Minimum width for cropping area
                    minHeight={250} // Minimum height for cropping area
                    viewMode={1} // Prevent resizing the crop box
                  />
                  <Button
                  variant="contained"
                  onClick={handleCrop}
                  style={{ marginTop: "16px" }}
                >
                  Crop Image
                </Button>
                </Box>
                
              </>
            )}

            {croppedImage && (
              <Box mt={2} style={{ textAlign: "center" }}>
                {/* <Typography variant="h6">Cropped Image Preview</Typography> */}
                {/* <img
                  src={croppedImage}
                  alt="Cropped Preview"
                  className={styles.previewImage}
                /> */}
              </Box>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={applyForAllPractices}
                  onChange={(e) => setApplyForAllPractices(e.target.checked)}
                  name="applyForAllPractices"
                />
              }
              label="Apply to all practices"
            />

<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
  <Button
    variant="contained"
    onClick={handleSubmit}
    disabled={uploading}
  >
    {uploading ? <CircularProgress size={24} /> : "Submit"}
  </Button>
</Box>
          </>
        )}

        <Dialog
          open={isConfirmationOpen}
          onClose={() => setIsConfirmationOpen(false)}
        >
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to apply this update to all practices?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsConfirmationOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={uploadData} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
