import React, { useState } from 'react';
import styles from './PasswordPrompt.module.scss';

const PasswordPrompt = ({ onSubmit, onCancel }) => {
  const [password, setPassword] = useState('');
  const correctPassword = 'medicubeAdmin';

  const handleSubmit = () => {
    if (password === correctPassword) {
      onSubmit();
    } else {
      alert('Incorrect password');
    }
  };

  // Handle cancel button click to redirect to /dashboard
  const handleCancel = () => {
    window.location.replace('/dashboard'); // Use window.location.replace for redirection
  };

  return (
    <div className={styles.passwordPrompt}>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter password"
        className={styles.input}
      />
      <button onClick={handleSubmit} className={styles.submitButton}>
        Submit
      </button>
      <button onClick={handleCancel} className={styles.cancelButton}>
        Cancel
      </button>
    </div>
  );
};

export default PasswordPrompt;
